
/**
 * @roxi/routify 2.15.1
 * File generated Mon Jul 19 2021 11:10:33 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.15.1"
export const __timestamp = "2021-07-19T11:10:33.409Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/stages/one",
          "id": "_stages_one",
          "component": () => import('../src/pages/stages/one.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/stages",
      "id": "_stages__layout",
      "component": () => import('../src/pages/stages/_layout.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

